@tailwind base;
@tailwind components;
@tailwind utilities;

.dark-theme {
  --brand: #364273;
  --color: #fff;
}

.light-theme {
  --brand: #fff;
  --color: #364273;
}

.menu {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem 1.5rem;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: medium;
  color: var(--nav_link);
}

.setting-menu {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: medium;
  color: var(--nav_link);
}

.active {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  font-size: 18px;
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
  text-transform: capitalize;
  font-weight: medium;
  background-color: var(--active);
  color: var(--active_text);
}
.setting-active {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem 1.5rem;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: medium;
  background: #f2f2f2;
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 2px;
  height: 0px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* border-radius: 10px; */
  height: 3px;
  width: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  margin: 40rem 0;
}

.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #2079a3;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #2079a3;
}

button:disabled {
  opacity: 0.4;
  cursor: "not-allowed";
}
.view-employee {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--container_color);
}
.view-employee-data {
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  color: var(--container_color);
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: var(--backdrop);
  z-index: 10;
  overflow: hidden;
}
