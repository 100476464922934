.modal {
  position: absolute;
  top: 10vh;
  left: 0%;
  width: 100%;
  background-color: var(--modal);
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 60;
  max-height: 80vh;
  animation: slide-down 300ms ease-out forwards;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
